<template>
    <div>
        <div v-if="marketing_banners_loading_status == true" class="text-center">
            <b-spinner small type="grow"></b-spinner>
            Loading Marketing Banners...
        </div>
        <div v-else>
            <webix-ui :config="ui" v-model="marketing_banners"></webix-ui>
            <!-- <div id="marketingBannersTablePaging"></div> -->
        </div>
    </div>
</template>

<script>
import { crmMethods, crmComputed } from "@/state/helpers";

export default {
    data: () => ({}),
    computed: {
        ...crmComputed,
        ui() {
            //eslint-disable-next-line
            let el = this;
            //eslint-disable-next-line
            let ui = {
                id: "marketingBannersTable",
                view: "datatable",
                css: "datatableUsers webix_header_border webix_data_border",
                autoheight: true,
                rowHeight: 200,
                scrollY: true,
                scrollX: true,
                resizeColumn: true,
                select: false,
                columns: [
                    {
                        id: "actions",
                        header: { text: "Actions", css: { "text-align": "center" } },
                        template: function () {
                            return `<div class="tableIcons">
                                <i class="mdi mdi-trash-can deleteAction"></i>
                            </div>`;
                        },
                    },
                    {
                        id: "role_name",
                        header: ["Role"],
                        tooltip: false,

                        sort: "text",
                    },
                    {
                        id: "public_s3_src",
                        header: ["Banner"],
                        tooltip: false,
                        fillspace: 1,
                        sort: "text",
                        template: function (obj) {
                            return `<div class="tableIcons">
                                <img src="${obj.public_s3_src}"></img>
                            </div>`;
                        },
                    },
                ],
                ready: function () {},
                on: {},
                onClick: {
                    //eslint-disable-next-line
                    viewAction: function (id, e, trg) {
                        let item = this.getItem(e.row);
                        let el = this.$scope.$parent;

                        el.setSelectedRoleForGroupDetails(item);
                        el.showPricingModal();
                    },
                    //eslint-disable-next-line
                    deleteAction: function (id, e, trg) {
                        const el = this.$scope.$parent;
                        const item = this.getItem(e.row);

                        el.handleSwal(item);
                    },
                },
            };
            return ui;
        },
    },
    methods: {
        ...crmMethods,
        handleSwal(item) {
            this.$swal
                .fire({
                    title: "Are you sure you want to delete the banner for " + item.role_name + "?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, delete it!",
                })
                .then((result) => {
                    if (result.value) {
                        this.deleteMarketingBanner({ id: item.id }).then(() => {
                            this.setMarketingBanners();
                            this.$swal.fire(`Deleted banner for ${item.role_name}`, "", "success");
                        });
                    }
                });
        },
    },
    mounted() {
        this.setMarketingBanners();
    },
};
</script>

<style lang="scss">
.pointer {
    cursor: pointer;
}
</style>

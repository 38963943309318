<template>
    <b-modal title="Add a Marketing Banner" id="addMarketingBannerModal" hide-footer size="md">
        <b-overlay :show="busySaving">
            <div>
                <b-form-group label="Who must see this banner:">
                    <b-form-select
                        v-model="selectedRole"
                        :options="role_options"
                        id="client-type"
                        name="client-type"
                        aria-describedby="client-type-live-feedback"
                    ></b-form-select>
                    <b-form-invalid-feedback id="client-type-live-feedback">This is a required field.</b-form-invalid-feedback>
                </b-form-group>
            </div>
            <!-- {{ file_src }} -->
            <!-- <div class="image-dive" :src="file_src"></div> -->
            <!-- <img :src="file_src" alt="" class="w-100" /> -->

            <div v-if="selectedRole" class="mt-5">
                <div v-if="selectedRole && show_upload">
                    <h6>Make sure it is 1000 x 200</h6>
                    <uploader
                        :options="renderUploadOptions"
                        :autoStart="false"
                        :ref="'attach_upload'"
                        class="uploader-example"
                        @change="getImage($event)"
                    >
                        <uploader-unsupport></uploader-unsupport>
                        <uploader-drop v-if="file_src.length == 0">
                            <p>Drop banner here, or select to upload</p>
                            <uploader-btn @onChange="getFileName('test')" class="btn w-50">Select Banner</uploader-btn>
                        </uploader-drop>
                        <div v-else class="mt-3 mb-3 border p-3">
                            <b-img :src="file_src" fluid alt="Responsive image"></b-img>
                        </div>

                        <!-- <uploader-list></uploader-list> -->
                    </uploader>

                    <b-button v-if="file_src.length > 0" variant="success" class="w-100 mt-4" @click="saveBanner()">Save Banner</b-button>
                </div>
            </div>
            <div class="modal-footer mt-2">
                <!-- <b-button @click="changeBanner" type="submit" variant="primary">Save</b-button> -->
                <button type="button" class="btn btn-secondary" @click="closeModal()">Cancel</button>
            </div>
        </b-overlay>
    </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { adminMethods, crmComputed, crmMethods } from "@/state/helpers";
//eslint-disable-next-line
import Swal from "sweetalert2";

export default {
    mixins: [validationMixin],
    data: () => ({
        selectedRole: null,
        busySaving: false,
        role_options: [
            { value: null, text: "Please select an option", disabled: true },
            { value: "3", text: "Representative / Reseller" },
            { value: "4", text: "Stockist" },
            { value: "5", text: "Individual" },
        ],
        show_upload: true,
        file_upload_type: null,
        attrs: {
            accept: "image/*",
        },
        file_src: "",
    }),
    watch: {
        file_upload_type(newVal) {
            if (newVal == null) {
                this.show_upload = false;
            } else {
                this.$nextTick(() => {
                    this.show_upload = true;
                });
            }
        },
        // selected_order_details() {
        //     //eslint-disable-next-line
        //     console.log("it was updated");
        // },
    },
    computed: {
        ...crmComputed,

        returnUploadTitle() {
            if (this.file_upload_type == null) {
                return "";
            }
            return `Upload file for ${this.file_upload_type}`;
        },
        renderUploadOptions() {
            var storage = window.localStorage;
            var AUTH_TOKEN = storage.getItem("x_token");
            var url = process.env.VUE_APP_API_BASEURL + "api/crm/uploadbanner";
            var options = {
                target: url,
                query: {
                    role_id: this.selectedRole,
                },
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN,
                },
                testChunks: false,
                withCredentials: false,
                fileParameterName: "banner",
                singleFile: true, //Limit upload to one file
            }; //
            //eslint-disable-next-line
            console.log("options was ruin");
            return options;
        },
        getFileName(e) {
            //eslint-disable-next-line
            console.log(e);
            return "";
        },
    },
    methods: {
        ...adminMethods,
        ...crmMethods,
        getImage(e) {
            const file = e.target.files[0];
            this.file_src = URL.createObjectURL(file);
        },
        //eslint-disable-next-line
        onFileAdded(event) {},
        saveBanner() {
            const uploaderInstance = this.$refs["attach_upload"];
            const upload = uploaderInstance.uploader;
            upload.opts.query.name = this.file_upload_type;
            // upload.opts.query.order_id = this.ext_order.id;
            upload.resume();
            //eslint-disable-next-line no-unused-vars
            upload.on("fileSuccess", (rootFile, file, message, chunk) => {
                var response = JSON.parse(message);
                if (response.success) {
                    this.$swal.fire("Banner Uploaded", "", "success");
                    upload.off("fileSuccess");
                    upload.cancel();

                    this.setMarketingBanners();

                    this.closeModal();
                } else {
                    //eslint-disable-next-line no-console
                    console.log("Upload error");
                }
            });
        },
        // changeBanner() {
        //     this.renderUploadOptions;
        // },
        closeModal() {
            this.$bvModal.hide("addMarketingBannerModal");
        },

        //eslint-disable-next-line
        onFileSuccess(e) {
            // this.getProductDetails().then(() => {
            //     this.busySaving = false;
            // });
            //eslint-disable-next-line
            console.log("file was success", e);
        },
    },
    mounted() {},
};
</script>
<style>
input[readonly] {
    background-color: red;
}
</style>

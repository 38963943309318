<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="mb-2 d-flex justify-content-between">
                            <h5 class="mt-2">Roles with a marketing banner</h5>
                            <button type="button" class="btn btn-success mb-2 mr-2" @click="showMarketingBanner">
                                <i class="mdi mdi-plus mr-1"></i> Add Marketing Banner
                            </button>
                        </div>
                        <MarketingBannersTable />
                    </div>
                </div>
            </div>
        </div>
        <AddMarketingBannerModal v-if="show_modal" />
    </div>
</template>

<script>
import MarketingBannersTable from "./tables/marketingBannersTable.vue";
import AddMarketingBannerModal from "./modals/addMarketingBannerModal.vue";

export default {
    components: { MarketingBannersTable, AddMarketingBannerModal },
    data: () => ({
        show_modal: false,
    }),
    computed: {},
    methods: {
        showMarketingBanner() {
            this.show_modal = true;
            this.$nextTick(() => {
                this.$bvModal.show("addMarketingBannerModal");
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "addMarketingBannerModal") {
                        this.show_modal = false;
                    }
                });
            });
        },
    },
    mounted() {},
};
</script>

<style></style>
